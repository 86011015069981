/* from https://github.com/aus/led.css / http://austinrobertson.com/blog/2013/01/30/css-led/ */
.led {
	margin: 20px auto;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.25);
	box-shadow: #000 0 -1px 6px 1px;
}
.led-red {
	background-color: #F00;
	box-shadow: #000 0 -1px 6px 1px, inset #600 0 -1px 8px, #F00 0 3px 11px;
}
.led-orange {
	background-color: #F90;
	box-shadow: #000 0 -1px 6px 1px, inset #630 0 -1px 8px, #F90 0 3px 11px;
}

.led-yellow {
	background-color: #FF0;
	box-shadow: #000 0 -1px 6px 1px, inset #660 0 -1px 8px, #FF0 0 3px 11px;
}

.led-green {
	background-color: #6F0;
	box-shadow: #000 0 -1px 6px 1px, inset #460 0 -1px 8px, #6F0 0 3px 11px;
}

.led-blue {
	background-color: #06F;
	box-shadow: #000 0 -1px 6px 1px, inset #006 0 -1px 8px, #06F 0 3px 11px;
}

/* Based on http://stackoverflow.com/questions/16344354/how-to-make-blinking-flashing-text-with-css3 */
.led-flash {
	animation-name: blinker;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.led-flash-fast {
	animation-name: blinker;
	animation-duration: 0.5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

@keyframes blinker {
	0% { opacity: 1.0; }
	50% { opacity: 0.0; }
	100% { opacity: 1.0; }
}
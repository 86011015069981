.has-tooltip {
  cursor: pointer;
  position: relative;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.has-tooltip .tooltip {
  @extend .bg-dark;
  @extend .white;
  bottom: 100%;
  display: block;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  width: 95%;
  transform: translateY(10px);
  transition: all .25s ease-out;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-sizing: border-box;
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.has-tooltip .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.has-tooltip .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid $dark-gray 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.has-tooltip:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0px);
}
// From https://github.com/l-lin/font-awesome-animation

/* WRENCHING */
@keyframes wrench {
	0%{transform:rotate(-12deg)}
	8%{transform:rotate(12deg)}
	10%{transform:rotate(24deg)}
	18%{transform:rotate(-24deg)}
	20%{transform:rotate(-24deg)}
	28%{transform:rotate(24deg)}
	30%{transform:rotate(24deg)}
	38%{transform:rotate(-24deg)}
	40%{transform:rotate(-24deg)}
	48%{transform:rotate(24deg)}
	50%{transform:rotate(24deg)}
	58%{transform:rotate(-24deg)}
	60%{transform:rotate(-24deg)}
	68%{transform:rotate(24deg)}
	75%{transform:rotate(0deg)}
}
.icona-wrench.animated,
.icona-wrench.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-wrench {
	animation: wrench 2.5s ease infinite;
	transform-origin-x: 90%;
	transform-origin-y: 35%;
	transform-origin-z: initial;
}
.icona-wrench.animated.icona-fast,
.icona-wrench.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-wrench.icona-fast {
	animation: wrench 1.2s ease infinite;
}
.icona-wrench.animated.icona-slow,
.icona-wrench.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-wrench.icona-slow {
	animation: wrench 3.7s ease infinite;
}

/* BELL */
@keyframes ring {
	0%{transform:rotate(-15deg)}
	2%{transform:rotate(15deg)}
	4%{transform:rotate(-18deg)}
	6%{transform:rotate(18deg)}
	8%{transform:rotate(-22deg)}
	10%{transform:rotate(22deg)}
	12%{transform:rotate(-18deg)}
	14%{transform:rotate(18deg)}
	16%{transform:rotate(-12deg)}
	18%{transform:rotate(12deg)}
	20%{transform:rotate(0deg)}
}
.icona-ring.animated,
.icona-ring.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-ring {
	animation: ring 2s ease infinite;
	transform-origin-x: 50%;
	transform-origin-y: 0px;
	transform-origin-z: initial;
}
.icona-ring.animated.icona-fast,
.icona-ring.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-ring.icona-fast {
	animation: ring 1s ease infinite;
}
.icona-ring.animated.icona-slow,
.icona-ring.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-ring.icona-slow {
	animation: ring 3s ease infinite;
}

/* VERTICAL */
@keyframes vertical {
	0%{transform:translate(0,-3px)}
	4%{transform:translate(0,3px)}
	8%{transform:translate(0,-3px)}
	12%{transform:translate(0,3px)}
	16%{transform:translate(0,-3px)}
	20%{transform:translate(0,3px)}
	22%{transform:translate(0,0)}
}
.icona-vertical.animated,
.icona-vertical.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-vertical {
	animation: vertical 2s ease infinite;
}
.icona-vertical.animated.icona-fast,
.icona-vertical.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-vertical.icona-fast {
	animation: vertical 1s ease infinite;
}
.icona-vertical.animated.icona-slow,
.icona-vertical.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-vertical.icona-slow {
	animation: vertical 4s ease infinite;
}

/* HORIZONTAL */
@keyframes horizontal {
	0%{transform:translate(0,0)}
	6%{transform:translate(5px,0)}
	12%{transform:translate(0,0)}
	18%{transform:translate(5px,0)}
	24%{transform:translate(0,0)}
	30%{transform:translate(5px,0)}
	36%{transform:translate(0,0)}
}
.icona-horizontal.animated,
.icona-horizontal.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-horizontal {
	animation: horizontal 2s ease infinite;
}
.icona-horizontal.animated.icona-fast,
.icona-horizontal.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-horizontal.icona-fast {
	animation: horizontal 1s ease infinite;
}
.icona-horizontal.animated.icona-slow,
.icona-horizontal.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-horizontal.icona-slow {
	animation: horizontal 3s ease infinite;
}

/* FLASHING */
@keyframes flash {
	0%,100%,50%{opacity:1}
	25%,75%{opacity:0}
}
.icona-flash.animated,
.icona-flash.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-flash {
	animation: flash 2s ease infinite;
}
.icona-flash.animated.icona-fast,
.icona-flash.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-flash.icona-fast {
	animation: flash 1s ease infinite;
}
.icona-flash.animated.icona-slow,
.icona-flash.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-flash.icona-slow {
	animation: flash 3s ease infinite;
}

/* BOUNCE */
@keyframes bounce {
	0%,10%,20%,50%,80%{transform:translateY(0)}
	40%{transform:translateY(-15px)}
	60%{transform:translateY(-15px)}
}
.icona-bounce.animated,
.icona-bounce.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-bounce {
	animation: bounce 2s ease infinite;
}
.icona-bounce.animated.icona-fast,
.icona-bounce.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-bounce.icona-fast {
	animation: bounce 1s ease infinite;
}
.icona-bounce.animated.icona-slow,
.icona-bounce.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-bounce.icona-slow {
	animation: bounce 3s ease infinite;
}

/* SPIN */
@keyframes spin{
	0%{transform:rotate(0deg)}
	100%{transform:rotate(359deg)}
}
.icona-spin.animated,
.icona-spin.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-spin {
	animation: spin 1.5s linear infinite;
}
.icona-spin.animated.icona-fast,
.icona-spin.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-spin.icona-fast {
	animation: spin 0.7s linear infinite;
}
.icona-spin.animated.icona-slow,
.icona-spin.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-spin.icona-slow {
	animation: spin 2.2s linear infinite;
}

/* FLOAT */
@keyframes float{
	0%{transform: translateY(0)}
	50%{transform: translateY(-6px)}
	100%{transform: translateY(0)}
}
.icona-float.animated,
.icona-float.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-float {
	animation: float 2s linear infinite;
}
.icona-float.animated.icona-fast,
.icona-float.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-float.icona-fast {
	animation: float 1s linear infinite;
}
.icona-float.animated.icona-slow,
.icona-float.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-float.icona-slow {
	animation: float 3s linear infinite;
}

/* PULSE */
@keyframes pulse {
	0% {transform: scale(1.1)}
 	50% {transform: scale(0.8)}
 	100% {transform: scale(1.1)}
}
.icona-pulse.animated,
.icona-pulse.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-pulse {
	animation: pulse 2s linear infinite;
}
.icona-pulse.animated.icona-fast,
.icona-pulse.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-pulse.icona-fast {
	animation: pulse 1s linear infinite;
}
.icona-pulse.animated.icona-slow,
.icona-pulse.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-pulse.icona-slow {
	animation: pulse 3s linear infinite;
}

/* SHAKE */
.icona-shake.animated,
.icona-shake.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-shake {
	animation: wrench 2.5s ease infinite;
}
.icona-shake.animated.icona-fast,
.icona-shake.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-shake.icona-fast {
	animation: wrench 1.2s ease infinite;
}
.icona-shake.animated.icona-slow,
.icona-shake.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-shake.icona-slow {
	animation: wrench 3.7s ease infinite;
}

/* TADA */
@keyframes tada {
	0% {transform: scale(1)}
	10%,20% {transform:scale(.9) rotate(-8deg);}
	30%,50%,70% {transform:scale(1.3) rotate(8deg)}
	40%,60% {transform:scale(1.3) rotate(-8deg)}
	80% {transform:scale(1) rotate(0)}
}

.icona-tada.animated,
.icona-tada.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-tada {
	animation: tada 2s linear infinite;
}
.icona-tada.animated.icona-fast,
.icona-tada.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-tada.icona-fast {
	animation: tada 1s linear infinite;
}
.icona-tada.animated.icona-slow,
.icona-tada.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-tada.icona-slow {
	animation: tada 3s linear infinite;
}

/* PASSING */
@keyframes passing {
	0% {transform:translateX(-50%); opacity:0}
	50% {transform:translateX(0%); opacity:1}
	100% {transform:translateX(50%); opacity:0}
}

.icona-passing.animated,
.icona-passing.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-passing {
	animation: passing 2s linear infinite;
}
.icona-passing.animated.icona-fast,
.icona-passing.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-passing.icona-fast {
	animation: passing 1s linear infinite;
}
.icona-passing.animated.icona-slow,
.icona-passing.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-passing.icona-slow {
	animation: passing 3s linear infinite;
}

/* WAVE */
@keyframes burst {
	0% {opacity:.6}
	50% {transform:scale(1.8);opacity:0}
	100%{opacity:0}
}
.icona-burst.animated,
.icona-burst.animated-hover:hover,
.icona-parent.animated-hover:hover > .icona-burst {
	animation: burst 2s infinite linear
}
.icona-burst.animated.icona-fast,
.icona-burst.animated-hover.icona-fast:hover,
.icona-parent.animated-hover:hover > .icona-burst.icona-fast {
	animation: burst 1s infinite linear
}
.icona-burst.animated.icona-slow,
.icona-burst.animated-hover.icona-slow:hover,
.icona-parent.animated-hover:hover > .icona-burst.icona-slow {
	animation: burst 3s infinite linear
}
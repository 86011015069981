$container-width: 14.5em;

@import 'components/reset';
@import 'components/fibonacci';
@import 'components/colors';
@import 'components/buttons';
@import 'components/fonts';
@import 'components/chat';
@import 'components/video';
@import 'components/lists';
@import 'layout/app';
@import 'layout/active';
@import 'layout/playback';
@import 'login';
@import 'components/tooltips';
@import 'components/icons';
@import 'components/alertify/core';
@import 'components/alertify/default';
@import 'components/leds';
@import 'components/loader';
@import 'components/kogrid';
@import 'components/animated-icons';
@import 'components/leaflet';

$font-family-sans-serif: 'Roboto';

@import 'components/bootstrap';
@import 'components/daterangepicker';

/* sections */
#closeout {
  @extend .darker;
  @extend %absolute-full;

  text-align: center;

  .pane-title {
    margin: 2rem 0;
    font-size: 3rem;
  }
  .callerid {
    margin: 1rem 0;
    font-size: 1.5rem;
  }

  form {
    margin: 1em;
  }

  .form-group {
    text-align: left;
  }

  button {
    margin: 1rem 0;
    @extend %button;
    width: auto;
    padding: 0.5rem;
    height: auto;
  }
}

#closeout-stats {
  height: 10%;
  width: 78%;
  padding: 1%;
  border: none;
  border-bottom: thick solid $dark-gray;
}

#closeout-text {
  height: 60%;
  width: 78%;
  padding: 1%;
  border: none;
  line-height: 1em;
}

.hold {
}

li {
  list-style: none;
}

#message-text {
  height: 10em;
  width: 99%;
  border: none;
  line-height: 1em;
}

#new-chat-message {
  bottom: 0;
  height: 2em;
  width: 70%;
  position: absolute;
  border: none;

  @extend .text-xsmall;
  @extend .white;
  @extend .bg-medium;
}

.no-display {
  display: none;
}

.dossier-picture {
  margin: 0.5em auto;
  text-align: center;

  height: 120px;
  width: 90%;

  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.picture-rounded-yellow {
  border-radius: 50px;
  width: 100px;
  height: 100px;
  border:2px solid #FFDE17;
}

.main-list {
  .overlay {
    opacity: 0.3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &:hover {
      opacity: 0;
    }
  }

  .status-active .overlay {
    background: #0f0;
  }
  .status-new .overlay {
    background: #fc0;
  }
  .status-transfer .overlay {
    background: #f00;
  }
  .status-other-console .overlay {
    background: #000;
  }
  .status-closeout .overlay {
    background: #f0f;
  }
  .status-other-closeout {
    .overlay {
      background: #000;
    }
    cursor: not-allowed;
  }

  .indicator {
    color: #fff;
    height: 2rem;
    width: 2rem;
    font-size: 1.25rem;
    text-align: center;
    line-height: 2rem;
  }

  ul > li {
    @extend %absolute-container;
    margin-right: 6px;
    min-width: 130px;

    video {
      @extend %absolute-full;
    }
  }

  .callerid-overlay {
    position: absolute;
    bottom: 5px;
    width: 98%;
    text-align: center;
    color: #ff0;
    background: rgba(0,0,0,0.3);
    height: 2em;
    line-height: 2em;
    border-radius: 20px;
  }
}

#remoteVideoActive, #remoteVideoPlayback {
  object-fit: fill;
  position: absolute;
  // These need to be offset by the proper margins in JavaScript
  top: 50%;
  left: 50%;
}

span {
  position: relative;
  top: 50%;
}

video {
  /* not (yet?) widely supported */
  /*object-fit: cover;*/
  width: 100%;
}

.yellow-line-separator {
  height:1px;
  background:#717171;
  border-top:1px solid #FFDE17;
  width: 80%;
}

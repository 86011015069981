#screen-active-calls {
	@extend %columnParent;
	@extend %flexChild;
	z-index: 100;

	.main {
		order:1;
		@extend %rowParent;
		@extend %flexChild;
		flex: 8;
	}

	#remoteVideo {
		@extend %absolute-full;
	}

	#localVideo {
		position: absolute;
		height: 100px;
		width: 100px;
		left: -100%;
	}

	#video-container {
		order:1;
		@extend %flexChild;
		@extend %absolute-container;

		@extend .darker;

		background-image: url(../images/logo_name.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-color: #2D2D2D;
	}

	#call-menu {
		@extend %rowParent;

		background: rgba(0,0,0,0.5);

		text-align: center;

		height: 2em;
		line-height: 2em;

		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		margin: 0;

		li {
			@extend %flexChild;
			justify-content: space-around;

			cursor: pointer;
			text-shadow: #000 2px 2px 1px;

			[class^="icon-"], [class*=" icon-"] {
				padding-right: 3px;
				display: inline-block;
			}

			p {
				display: inline-block;
				margin: 0;
				font-size: 1.1rem;
			}

			&:hover {
				color: $yellow;
				background: rgba(0,0,0,0.5);
			}
		}
	}

	.map-container {
		order:2;
		@extend %flexChild;
		@extend %absolute-container;

		#active-map {
			@extend %absolute-full;
		}
	}

	.controls {
		order: 3;
		width: 47px;

		> ul > li {
			width: 70%;
			padding: .5em 0;
		}
	}

	.main-list {
		@extend .bg-medium-dark;

		@extend %flexChild;
		flex: 2;
		order: 2;
		max-height: 130px;
		min-height: 70px;
		@extend %absolute-container;

		ul {
			@extend %rowParent;
			@extend %absolute-full;

			margin: 0;

			.indicator {
				position: absolute;
				top: 6px;
				left: 6px;
			}

			.txt-indicator {
				position: absolute;
				top: 2em;
				right: 6px;
				text-align: center;
				width: 2em;
				height: 2em;

				.led {
					width: 16px;
					height: 16px;
				}
			}
		}
	}
}

#dossier-container {
	dl#basic-info, dl#extended-info {
		@extend %inline-dl;
	}
	ul#emergency-contacts {
		@extend %inline-list;
	}

	> .title {
		background: #ffde17;
		margin: 0;
		padding: 3px;
		font-weight: bold;
	}

	#dossier-name {
		padding-top: .3em;
		text-align: center;

		@extend .text-regular;
		@extend .white;
	}

	#basic-info, #extended-info, #emergency-contacts {
		@extend .text-small;
		@extend .white;

		background:#717171;
		border-top:1px solid #FFDE17;
	}
}

#psap-container {
	dl#psap-location, dl#psap-police, dl#psap-fire, dl#psap-medical, dl#psap-pers, dl#psap-247 {
		@extend %inline-dl;

		@extend .text-small;
		@extend .white;

		background:#717171;
		border-top:1px solid #FFDE17;
	}

	> .title {
		background: #ffde17;
		margin: 0;
		padding: 3px;
		font-weight: bold;
	}
}
.video-controls {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	text-align: center;
	padding: 0.25rem;
	height: 1.5em;
	background: rgba(0,0,0,0.1);

	button {
		background-color: $dark-gray;
		border: none;
		color: $yellow;
	}
	button.disabled {
		color: $medium-gray;
	}
}
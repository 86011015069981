/* reset */
html, body,
h1, h2, h3, h4,
div, iframe
span, em,
ul, ol, li,
video {
  margin: 0;
  padding: 0;
}

html, body,
ul, ol, li,
video {
  height: 100%;
  /*width: 100%;*/
}
$light-gray: #D2D2D2;
$semi-medium-gray: #717171;
$medium-gray: #4D4D4D;
$dark-gray: #2D2D2D;
$yellow: #FFDE17;
$translucent-white: rgba(255, 255, 255, 0.7);

.bg-light {
  background-color: $light-gray;
}

.bg-medium {
  background-color: $semi-medium-gray;
}

.bg-medium-dark {
  background-color: $medium-gray;
}

.bg-dark {
  background-color: $dark-gray;
}

.yellow {
  color: $yellow;
}

.white {
  color: white;
}

/* base palettes */
.muted {
  background: #afafaf;
  color: #222222;
}

.darker {
  background: #262626;
  color: #f1f1f1;
}
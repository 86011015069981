%inline-dl {
	overflow: auto;

	dt {
		clear: left;
		float: left;
		width: 20px;
		margin: 0;
		padding: 5px;
	}

	dd {
		float: left;
		margin: 0;
		padding: 5px;
	}
}

%inline-list {
	overflow: auto;
	height: auto;

	li {
		padding: 5px;
		height: auto;
	}
}

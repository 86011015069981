// Based on:
// - http://jsfiddle.net/anuradhabajpai/x8C8S/10/
// - http://cssdeck.com/labs/6mifhkdc
// - http://codepen.io/chriscoyier/pen/FCIap

#chat-history {
	@extend .text-small;

	width: 100%;
	padding: 0 1rem 0.5rem;
	box-sizing: border-box;
	overflow-y: scroll;
	/*max-*/height: 95%;
}

%chat-bubble {
	margin-top: 20px;
	padding: 5px 9px;
	max-width: 90%;
	clear: both;
	position: relative;

	background-color: $dark-gray;
	color: $yellow;

	&:before {
		border-bottom: 10px solid $dark-gray;
		position: absolute;
		bottom: 0;
		content: "";
	}
}

.chat-msg-left {
	@extend %chat-bubble;

	float: left;
	margin-right: auto;
	border-radius: 8px 8px 8px 0px;

	&:before {
		border-left: 9px solid rgba(0, 0, 0, 0);
		left: -8px;
	}
}

.chat-msg-right {
	@extend %chat-bubble;

	float: right;
	margin-left: auto;
	text-align: right;
	border-radius: 8px 8px 0px 8px;

	&:before {
		border-right: 9px solid rgba(0, 0, 0, 0);
		right: -8px;
	}
}

.chat-time-left {
	float: left;
	margin-right: auto;
	font-size: small;
}

.chat-time-right {
	float: right;
	margin-left: auto;
	text-align: right;
	font-size: small;
}
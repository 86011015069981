%button {
  height: 1.5em;
  width: 35%;
  color: #FFDE17;
  background-color: #717171;
  border: none;
  margin: auto;
  border-radius: 5px;
  font-size: 1.5em;
  cursor: pointer;
}

#chat-container button[type=submit] {
  bottom: 0;
  height: 2.2em;
  width: 28%;
  right: 0;
  color: #FFDE17;
  background-color: #2D2D2D;
  position: absolute;
  border: none;
}

#login-button {
  bottom: 0;
  height: 1.5em;
  width: 35%;
  color: #FFDE17;
  background-color: #717171;
  border: none;
  margin: auto;
  border-radius: 5px;
  font-size: 1.5em;
}

#incident-submit {
  height: 2em;
  width: 80%;
  color: #2D2D2D;
  background-color: #FFDE17;
  border: none;
  font: 15pt helvetica;
}

#send {
  bottom: 0;
  height: 2.2em;
  width: 100%;
  color: #FFDE17;
  background-color: #2D2D2D;
  position: absolute;
  border: none;
}

/* ============================================================
  COMMON
============================================================ */
.settings {
  display: table;
  width: 100%;
  height: 20%;
}
.settings .row {
  position: relative;
  display: table-row;
}
.settings .question,
.settings .switch {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
}
.settings .question {
  font: helvetica, verdana, arial, sans-serif;
  font-size: 10pt;
  font-weight: 200;
  color: #FFDE17;
}

/* ============================================================
  COMMON
============================================================ */
.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.cmn-toggle-round-flat + label {
  padding: 2px;
  width: 40px;
  height: 20px;
  background-color: #2D2D2D;
  border-radius: 20px;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:before,
input.cmn-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}
input.cmn-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #717171;
  border-radius: 20px;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 16px;
  background-color: #2D2D2D;
  border-radius: 16px;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat:checked + label {
  background-color: #2D2D2D;
}
input.cmn-toggle-round-flat:checked + label:after {
  margin-left: 20px;
  background-color: #FFDE17;
}


.btn-show-on-map {
  background: #111;
  border:none;
  border-radius: 40px;
  position: absolute;
  top: 6px;
  right: 6px;
  height: 2rem;
  width: 2rem;
  display:block;
  color: #fff;
  font-size: 1.25rem;
  padding: 0;
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
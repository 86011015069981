html, body {
	min-height: 400px;
}

body {
	font-family: Roboto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

#app {
	width: 100%;
	height: 100%;

	@extend %rowParent;
	order: 1;

	background-color: $light-gray;
}

#screen-welcome {
	background-image: url(../images/logo_name.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-color: $dark-gray;
	flex: 1;
}

.controls {
	@extend %flexChild;
	flex: 0 0 auto;
	width: 47px;

	color: $medium-gray;
	@extend .bg-dark;

	font-family: 'Roboto Condensed';
	font-weight: 300;

	&.enabled {
		color: $yellow;
		cursor: pointer;
	}

	> ul {
		@extend %columnParent;
		justify-content: flex-start;
	    align-content: stretch;
   		align-items: center;

		> li {
			@extend %flexChild;
			flex: 0 1 auto;
			font-size: 30px;
			width: 100%;
			box-sizing: border-box;
			padding: 8px 4px;
			text-align: center;
			// Fix for Chrome 38 (NW.js 0.11.x)
			height: initial;

			border-bottom: 1px solid $semi-medium-gray;

			img {
				max-width: 100%;
			}

			&.open-bold {
				background: $light-gray;
			}
			&.open, &.open-bold {
				[class^="icon-"], [class*=" icon-"] {
					color: $semi-medium-gray;
				}

				> p {
					color: $semi-medium-gray;
				}
			}

			[class^="icon-"]:before, [class*=" icon-"]:before {
				margin: 0;
			}

			> p {
				margin: 0;
				font-size: 1.1rem;
			}
		}
	}
}

#login-wrapper {
	display:flex;
	align-items: center;
	justify-content: center;

	background: $light-gray;

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	z-index: 1000;
}

#main-menu {
	@extend %flexChild;
	flex: 0 0 auto;
	width: 47px;
	order: 2;

	@extend %absolute-container;

	@extend .bg-dark;
	border-left: 2px groove #666;
}

#logout-button {
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	border-top: 1px solid #717171;
	padding: 8px 4px;
	box-sizing: border-box;
	font-size: 16px;

	p {
		margin: 0;
		font-size: 1.1rem;
	}
}

#version-container {
	@extend .bg-medium-dark;
	@extend .white;

	position: absolute;
	z-index: 200;
	height: 6em;
	width: $container-width;
	right: 47px;
	line-height: 1rem;
	text-align: center;
	top: 1px;
	box-shadow: -2px 2px 5px $translucent-white;
	padding: 0.5rem;
}

%absolute-container {
	position: relative;
}
%absolute-full {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

#sms-container {
	@extend .bg-medium-dark;

	position: absolute;
	z-index: 2;
	height: 20em;
	width: $container-width;
	right: 45px;
	flex-direction: column;
}

#dossier-container {
	position: absolute;
	flex-direction: column;
	align-items: center;
	z-index: 2;
	width: 20em;
	right: 96px;
	top: 10px;

	@extend .bg-medium-dark;

	bottom: 180px;
	overflow: auto;
	z-index: 100;
}

#chat-container {
	@extend .bg-medium-dark;

	position: absolute;
	z-index: 2;
	width: $container-width;
	right: 96px;
	top: 10px;
	bottom: 180px;
	overflow: auto;
	z-index: 100;
}

#psap-container {
	@extend .bg-medium-dark;

	position: absolute;
	z-index: 2;
	width: 20em;
	right: 96px;
	top: 10px;
	bottom: 160px;
	overflow: auto;
	z-index: 100;
}

body > #active-map {
	width: 200px;
	height: 200px;
	z-index: 1;
	position: absolute;
	left: -1000px;
}


#login-content {
	display: flex;
	flex-flow: column;

	justify-content: center;
	text-align: center;

	width: 25em;
	height: 25em;
	background-color: #2D2D2D;
	border-radius: 10px;

	input {
		font-size: 1.1em;
	}

	input {
		bottom: 0;
		height: 1.5em;
		width: 70%;
		background-color: #717171;
		border: none;
		margin: auto;
		color: $yellow;
		border-radius: 5px;
		font-size: 1.2em;
		font-family: Roboto;
		font-weight: 400;
		line-height: 1.5em;
		padding: 0.15rem 0.5rem;
	}
}

input#login:invalid {
	background-color: rgba(255,0,0,0.5);
}

.login-errors p {
	@extend .yellow;

	margin: 0;
}

#login-img {
	width: 50%;
	margin: auto;
}

#login-content>label {
	color: $yellow;
}
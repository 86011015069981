// Shortcut styles from http://maxsteenbergen.com/fibonacci/

%rowParent, %columnParent{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}

%columnParent{
  flex-direction: column;
}

%flexChild{
  flex: 1;
  align-self: auto;
}
@font-face {
  font-family: 'safermobility-icons';
  src: url('../font/safermobility-icons.woff2?54546236') format('woff2');
  font-weight: normal;
  font-style: normal;
}

 [class^="icon-"]:before, [class*=" icon-"]:before,
 [class^="fa-"]:before, [class*=" fa-"]:before {
  font-family: "safermobility-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-2x {
  font-size: 2em;
}
.icon-3x {
  font-size: 3em;
}
.icon-4x {
  font-size: 4em;
}
.icon-5x {
  font-size: 5em;
}

.icon-alert:before { content: '\e800'; } /* '' */
.icon-dossier:before { content: '\e801'; } /* '' */
.icon-messages:before { content: '\e802'; } /* '' */
.icon-user:before { content: '\e803'; } /* '' */
.icon-mic:before { content: '\e804'; } /* '' */
.icon-mute:before { content: '\e805'; } /* '' */
.icon-phone:before { content: '\e806'; } /* '' */
.icon-cog:before { content: '\e807'; } /* '' */
.icon-cog-alt:before { content: '\e808'; } /* '' */
.icon-wrench:before { content: '\e809'; } /* '' */
.icon-sliders:before { content: '\e80a'; } /* '' */
.icon-toggle-off:before { content: '\e80b'; } /* '' */
.icon-toggle-on:before { content: '\e80c'; } /* '' */
.icon-birthday:before { content: '\e80d'; } /* '' */
.icon-droplet:before { content: '\e80e'; } /* '' */
.icon-mail:before { content: '\e80f'; } /* '' */
.icon-mail-alt:before { content: '\e810'; } /* '' */
.icon-home:before { content: '\e811'; } /* '' */
.icon-medkit:before { content: '\e812'; } /* '' */
.icon-language:before { content: '\e813'; } /* '' */
.icon-hearing-impaired:before { content: '\e814'; } /* '' */
.icon-resize-vertical:before { content: '\e815'; } /* '' */
.icon-resize-horizontal:before { content: '\e816'; } /* '' */
.icon-users:before { content: '\e817'; } /* '' */
.icon-hangup:before { content: '\e818'; } /* '' */
.icon-exchange:before { content: '\e819'; } /* '' */
.icon-export:before { content: '\e81a'; } /* '' */
.icon-export-alt:before { content: '\e81b'; } /* '' */
.icon-volume-off:before { content: '\e81c'; } /* '' */
.icon-volume-down:before { content: '\e81d'; } /* '' */
.icon-volume-up:before { content: '\e81e'; } /* '' */
.icon-headphones:before { content: '\e81f'; } /* '' */
.icon-pause:before { content: '\e820'; } /* '' */
.icon-to-end:before { content: '\e821'; } /* '' */
.icon-to-end-alt:before { content: '\e822'; } /* '' */
.icon-to-start:before { content: '\e823'; } /* '' */
.icon-to-start-alt:before { content: '\e824'; } /* '' */
.icon-fast-fw:before { content: '\e825'; } /* '' */
.icon-fast-bw:before { content: '\e826'; } /* '' */
.icon-play:before { content: '\e827'; } /* '' */
.icon-play-circled:before { content: '\e828'; } /* '' */
.icon-play-circled2:before { content: '\e829'; } /* '' */
.icon-stop:before { content: '\e82a'; } /* '' */
.icon-expand:before { content: '\e82b'; } /* '' */
.icon-collapse:before { content: '\e82c'; } /* '' */
.icon-expand-right:before { content: '\e82d'; } /* '' */
.icon-collapse-left:before { content: '\e82e'; } /* '' */
.icon-down-big:before { content: '\e82f'; } /* '' */
.icon-left-big:before { content: '\e830'; } /* '' */
.icon-right-big:before { content: '\e831'; } /* '' */
.icon-up-open:before { content: '\e832'; } /* '' */
.icon-right-open:before { content: '\e833'; } /* '' */
.icon-up-big:before { content: '\e834'; } /* '' */
.icon-left-open:before { content: '\e835'; } /* '' */
.icon-down-open:before { content: '\e836'; } /* '' */
.icon-up-dir:before { content: '\e837'; } /* '' */
.icon-left-dir:before { content: '\e838'; } /* '' */
.icon-right-dir:before { content: '\e839'; } /* '' */
.icon-up:before { content: '\e83a'; } /* '' */
.icon-right:before { content: '\e83b'; } /* '' */
.icon-left:before { content: '\e83c'; } /* '' */
.icon-shuffle:before { content: '\e83d'; } /* '' */
.icon-history:before { content: '\e83e'; } /* '' */
.icon-angle-left:before { content: '\e83f'; } /* '' */
.icon-angle-right:before { content: '\e840'; } /* '' */
.icon-angle-up:before { content: '\e841'; } /* '' */
.icon-angle-down:before { content: '\e842'; } /* '' */
.icon-down-hand:before { content: '\e843'; } /* '' */
.icon-up-hand:before { content: '\e844'; } /* '' */
.icon-left-hand:before { content: '\e845'; } /* '' */
.icon-right-hand:before { content: '\e846'; } /* '' */
.icon-left-circled:before { content: '\e847'; } /* '' */
.icon-right-circled:before { content: '\e848'; } /* '' */
.icon-up-circled:before { content: '\e849'; } /* '' */
.icon-down-circled:before { content: '\e84a'; } /* '' */
.icon-angle-circled-down:before { content: '\e84b'; } /* '' */
.icon-angle-circled-up:before { content: '\e84c'; } /* '' */
.icon-angle-circled-right:before { content: '\e84d'; } /* '' */
.icon-angle-circled-left:before { content: '\e84e'; } /* '' */
.icon-cw:before { content: '\e84f'; } /* '' */
.icon-ccw:before { content: '\e850'; } /* '' */
.icon-arrows-cw:before { content: '\e851'; } /* '' */
.icon-level-up:before { content: '\e852'; } /* '' */
.icon-level-down:before { content: '\e853'; } /* '' */
.icon-down:before { content: '\e854'; } /* '' */
.icon-angle-double-down:before { content: '\e855'; } /* '' */
.icon-angle-double-up:before { content: '\e856'; } /* '' */
.icon-angle-double-right:before { content: '\e857'; } /* '' */
.icon-angle-double-left:before { content: '\e858'; } /* '' */
.icon-target:before { content: '\e859'; } /* '' */
.icon-move:before { content: '\e85a'; } /* '' */
.icon-zoom-in:before { content: '\e85b'; } /* '' */
.icon-zoom-out:before { content: '\e85c'; } /* '' */
.icon-down-circled2:before { content: '\e85d'; } /* '' */
.icon-up-circled2:before { content: '\e85e'; } /* '' */
.icon-left-circled2:before { content: '\e85f'; } /* '' */
.icon-right-circled2:before { content: '\e860'; } /* '' */
.icon-down-dir:before { content: '\e861'; } /* '' */
.icon-lightbulb:before { content: '\e862'; } /* '' */
.icon-block:before { content: '\e863'; } /* '' */
.icon-logout:before { content: '\e864'; } /* '' */
.icon-login:before { content: '\e865'; } /* '' */
.icon-phone-squared:before { content: '\e866'; } /* '' */
.icon-compass:before { content: '\e867'; } /* '' */
.icon-trash:before { content: '\e868'; } /* '' */
.icon-trash-empty:before { content: '\e869'; } /* '' */
.icon-file-video:before { content: '\e86a'; } /* '' */
.icon-folder:before { content: '\e86b'; } /* '' */
.icon-folder-open:before { content: '\e86c'; } /* '' */
.icon-folder-empty:before { content: '\e86d'; } /* '' */
.icon-folder-open-empty:before { content: '\e86e'; } /* '' */
.icon-desktop:before { content: '\e86f'; } /* '' */
.icon-laptop:before { content: '\e870'; } /* '' */
.icon-tablet:before { content: '\e871'; } /* '' */
.icon-mobile:before { content: '\e872'; } /* '' */
.icon-inbox:before { content: '\e873'; } /* '' */
.icon-male:before { content: '\e874'; } /* '' */
.icon-female:before { content: '\e875'; } /* '' */
.icon-video:before { content: '\e876'; } /* '' */
.icon-videocam:before { content: '\e877'; } /* '' */
.icon-camera:before { content: '\e878'; } /* '' */
.icon-camera-alt:before { content: '\e879'; } /* '' */
.icon-help:before { content: '\e87a'; } /* '' */
.icon-help-circled:before { content: '\e87b'; } /* '' */
.icon-info-circled:before { content: '\e87c'; } /* '' */
.icon-info:before { content: '\e87d'; } /* '' */
.icon-minus:before { content: '\e87e'; } /* '' */
.icon-plus:before { content: '\e87f'; } /* '' */
.icon-plus-circled:before { content: '\e880'; } /* '' */
.icon-plus-squared:before { content: '\e881'; } /* '' */
.icon-plus-squared-alt:before { content: '\e882'; } /* '' */
.icon-minus-circled:before { content: '\e883'; } /* '' */
.icon-minus-squared:before { content: '\e884'; } /* '' */
.icon-minus-squared-alt:before { content: '\e885'; } /* '' */
.icon-ok:before { content: '\e886'; } /* '' */
.icon-ok-circled:before { content: '\e887'; } /* '' */
.icon-ok-circled2:before { content: '\e888'; } /* '' */
.icon-ok-squared:before { content: '\e889'; } /* '' */
.icon-cancel:before { content: '\e88a'; } /* '' */
.icon-cancel-circled:before { content: '\e88b'; } /* '' */
.icon-cancel-circled2:before { content: '\e88c'; } /* '' */
.icon-attach:before { content: '\e88d'; } /* '' */
.icon-lock:before { content: '\e88e'; } /* '' */
.icon-lock-open:before { content: '\e88f'; } /* '' */
.icon-eye:before { content: '\e890'; } /* '' */
.icon-eye-off:before { content: '\e891'; } /* '' */
.icon-thumbs-up:before { content: '\e892'; } /* '' */
.icon-thumbs-down:before { content: '\e893'; } /* '' */
.icon-thumbs-up-alt:before { content: '\e894'; } /* '' */
.icon-thumbs-down-alt:before { content: '\e895'; } /* '' */
.icon-search:before { content: '\e896'; } /* '' */
.icon-attention-alt:before { content: '\e897'; } /* '' */
.icon-attention:before { content: '\e898'; } /* '' */
.icon-attention-circled:before { content: '\e899'; } /* '' */
.icon-location:before { content: '\e89a'; } /* '' */
.icon-paper-plane:before { content: '\e89b'; } /* '' */
.icon-paper-plane-empty:before { content: '\e89c'; } /* '' */
.icon-check:before { content: '\e89d'; } /* '' */
.icon-check-empty:before { content: '\e89e'; } /* '' */
.icon-at:before { content: '\e89f'; } /* '' */
.icon-language-1:before { content: '\e8a0'; } /* '' */
.icon-tty:before { content: '\e8a1'; } /* '' */
.icon-sort:before { content: '\e8a2'; } /* '' */
.icon-sort-down:before { content: '\e8a3'; } /* '' */
.icon-sort-up:before { content: '\e8a4'; } /* '' */
.icon-sort-alt-up:before { content: '\e8a5'; } /* '' */
.icon-sort-alt-down:before { content: '\e8a6'; } /* '' */
.icon-sort-name-up:before { content: '\e8a7'; } /* '' */
.icon-sort-name-down:before { content: '\e8a8'; } /* '' */
.icon-sort-number-up:before { content: '\e8a9'; } /* '' */
.icon-sort-number-down:before { content: '\e8aa'; } /* '' */
.icon-spinner:before { content: '\e8ab'; } /* '' */
.icon-reply:before { content: '\e8ac'; } /* '' */
.icon-reply-all:before { content: '\e8ad'; } /* '' */
.icon-forward:before { content: '\e8ae'; } /* '' */
.icon-quote-left:before { content: '\e8af'; } /* '' */
.icon-quote-right:before { content: '\e8b0'; } /* '' */
.icon-bell-off-empty:before { content: '\e8b1'; } /* '' */
.icon-bell-off:before { content: '\e8b2'; } /* '' */
.icon-bell-alt:before { content: '\e8b3'; } /* '' */
.icon-bell:before { content: '\e8b4'; } /* '' */
.icon-keyboard:before { content: '\e8b5'; } /* '' */
.icon-edit:before { content: '\e8b6'; } /* '' */
.icon-pencil-squared:before { content: '\e8b7'; } /* '' */
.icon-pencil:before { content: '\e8b8'; } /* '' */
.icon-tape:before { content: '\e8b9'; } /* '' */
.icon-building:before { content: '\e8ba'; } /* '' */
.icon-building-filled:before { content: '\e8bb'; } /* '' */
.icon-commerical-building:before { content: '\e8bc'; } /* '' */
.icon-industrial-building:before { content: '\e8bd'; } /* '' */
.icon-crop:before { content: '\e8be'; } /* '' */
.icon-flag-checkered:before { content: '\e8bf'; } /* '' */
.icon-flag-empty:before { content: '\e8c0'; } /* '' */
.icon-flag:before { content: '\e8c1'; } /* '' */
.icon-download:before { content: '\e8c2'; } /* '' */
.icon-download-cloud:before { content: '\e8c3'; } /* '' */
.icon-link-ext:before { content: '\e8c4'; } /* '' */
.icon-link-ext-alt:before { content: '\e8c5'; } /* '' */
.icon-unlink:before { content: '\e8c6'; } /* '' */
.icon-link:before { content: '\e8c7'; } /* '' */
.icon-th-large:before { content: '\e8c8'; } /* '' */
.icon-th:before { content: '\e8c9'; } /* '' */
.icon-th-list:before { content: '\e8ca'; } /* '' */
.icon-upload:before { content: '\e8cb'; } /* '' */
.icon-upload-cloud:before { content: '\e8cc'; } /* '' */
.icon-code:before { content: '\e8cd'; } /* '' */
.icon-retweet:before { content: '\e8ce'; } /* '' */
.icon-comment:before { content: '\e8cf'; } /* '' */
.icon-chat:before { content: '\e8d0'; } /* '' */
.icon-comment-empty:before { content: '\e8d1'; } /* '' */
.icon-chat-empty:before { content: '\e8d2'; } /* '' */
.icon-file-pdf:before { content: '\e8d3'; } /* '' */
.icon-clock:before { content: '\e8d4'; } /* '' */
.icon-resize-full:before { content: '\e8d5'; } /* '' */
.icon-resize-full-alt:before { content: '\e8d6'; } /* '' */
.icon-resize-small:before { content: '\e8d7'; } /* '' */
.icon-floppy:before { content: '\e8d8'; } /* '' */
.icon-filter:before { content: '\e8d9'; } /* '' */
.icon-taxi:before { content: '\e8da'; } /* '' */
.icon-android:before { content: '\e8db'; } /* '' */
.icon-apple:before { content: '\e8dc'; } /* '' */
.icon-calc:before { content: '\e8dd'; } /* '' */
.icon-calendar:before { content: '\e8de'; } /* '' */
.icon-police:before { content: '\e8df'; } /* '' */
.icon-location-1:before { content: '\e8e0'; } /* '' */
.icon-info-1:before { content: '\e8e1'; } /* '' */
.icon-shield:before { content: '\f132'; } /* '' */
.icon-bank:before { content: '\f19c'; } /* '' */
.icon-hashtag:before { content: '\f292'; } /* '' */
.icon-id-card-o:before { content: '\f2c3'; } /* '' */

// And also some aliases
.icon-transfer:before { content: '\e819'; } /* '' */
.fa-calendar:before { content: '\e8de'; } /* '' */
.fa-clock-o:before { content: '\e8d4'; } /* '' */
.fa-chevron-left:before { content: '\e835'; } /* '' */
.fa-chevron-right:before { content: '\e833'; } /* '' */

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.open .logo-icon, .spin {
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
